/* eslint-disable no-console */
import '../sass/index.scss';
// Lozad
import lozad from './util/lozad';
import {
	$body, lazyModule, $window, $document
} from './util/helpers';
import Polyfill from './polyfills';
// import SiteCookie from './cookies';

window.isAppLoaded = window.isAppLoaded || false;

/**
 * App component
 * @class App
 */
class App {
	/**
	 * Initialise app
	 * @function init
	 */
	browserDetect() {
		navigator.sayswho = (() => {
			const ua = navigator.userAgent;
			let tem;
			let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
			if (/trident/i.test(M[1])) {
				tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
				return `IE ${tem[1] || ''}`;
			}
			if (M[1] === 'Chrome') {
				tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
				if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
			}
			M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
			// eslint-disable-next-line no-cond-assign
			if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
			return M.join(' ');
		})();
		$body.addClass(navigator.sayswho);
	}

	init() {
		// initialise polyfills
		Polyfill();
		// Load modules
		lazyModule();

		// lazy loads elements with default selector as '.lozad'
		const observer = lozad();
		observer.observe();

		$document.on('ready', () => {
			$window.scrollTop(0);
			window.scrollTo(0, 0);
		});
		$window.on('unload', () => {
			$window.scrollTop(0);
		});

		$window.on('load', () => {
			this.browserDetect();
		});

		const currentOS = navigator.platform;
		$body.addClass(currentOS);

		// init Site cookies js
		// this.SiteCookie = new SiteCookie();
		// this.SiteCookie.init();
	}
}

/**
 * initialise app
 * @type function
 */
// eslint-disable-next-line func-names
export default (function () {
	// load CSS & thems
	if (!window.isAppLoaded) {
		// initialise app
		const gc = new App();
		gc.init();
		window.isAppLoaded = true;
	}
}());
